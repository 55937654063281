body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Lato', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  height: 100%;
}

body {
  overflow-y: scroll !important; /* https://github.com/mui/material-ui/issues/8710 */
  padding-right: 0 !important; /* https://github.com/mui/material-ui/issues/8710 */
}
